import { DateTime } from "luxon";

const NormalTimeFormat = "MM/dd/yy HH:mm ZZZZ";

/**
 * @description Converts an ISO string to a date time string setting the zone to the system tz or a specified zone.
 * @author Jacob Cutshall
 */
export default function convertIsoToDateTimeString(
  isoString: string,
  options?: {
    twelveHourFormat?: boolean;
    zone?: string;
  },
) {
  const { twelveHourFormat = false, zone = "system" } = options ? options : {};

  const dateObj = DateTime.fromISO(isoString, { zone: "utc" }).setZone(zone);

  if (twelveHourFormat) {
    return dateObj.toLocaleString(DateTime.DATETIME_SHORT);
  }

  return dateObj.toFormat(NormalTimeFormat);
}

/**
 * The function `convertIsoToDate` takes an ISO string and converts it to a formatted date string in
 * the specified time zone.
 * @param {string} isoString - The `isoString` parameter is a string representing a date and time in
 * ISO 8601 format, such as "2022-01-15T08:30:00Z".
 * @param [zone=utc] - The `zone` parameter in the `convertIsoToDate` function specifies the time zone
 * to use when converting the ISO string to a date. By default, it is set to "utc", but you can provide
 * a different time zone if needed.
 * @returns The function `convertIsoToDate` takes an ISO string and an optional time zone parameter,
 * then converts the ISO string to a DateTime object using the specified time zone (default is "utc"),
 * and finally returns the formatted date in the "MM/dd/yy" format.
 */
export function convertIsoToDate(isoString: string, zone = "utc") {
  // should we set the zone to system before format? I know this is used for subscription dates so mabe it should stay UTC
  return DateTime.fromISO(isoString, { zone }).toFormat("MM/dd/yy");
}

export function convertISOToJSDate(isoString: string) {
  return DateTime.fromISO(isoString, { zone: "utc" })
    .setZone("system") // TODO remove this
    .toJSDate();
}

export function convertSQLToDateTimeString(
  sqlString: string,
  options?: {
    twelveHourFormat?: boolean;
    zone?: string;
  },
) {
  const { twelveHourFormat = false, zone = "system" } = options ? options : {};

  const dateTimeObj = DateTime.fromSQL(sqlString, { zone: "utc" }).setZone(
    zone,
  );

  if (twelveHourFormat) {
    return dateTimeObj.toLocaleString(DateTime.DATETIME_SHORT);
  }

  return dateTimeObj.toFormat(NormalTimeFormat);
}

export function covnertSQLToJSDate(sqlString: string) {
  return DateTime.fromSQL(sqlString, { zone: "utc" })
    .setZone("system")
    .toJSDate();
}

export function convertJSDateToDateTimeString(
  jsDate: Date,
  options?: {
    twelveHourFormat?: boolean;
    zone?: string;
  },
) {
  const { twelveHourFormat = false, zone = "system" } = options ? options : {};
  const dateTimeObj = DateTime.fromJSDate(jsDate).setZone(zone);

  if (twelveHourFormat) {
    return dateTimeObj.toLocaleString(DateTime.DATETIME_SHORT);
  } else {
    return dateTimeObj.toFormat(NormalTimeFormat);
  }
}
